export default function Centig(props){

    document.title = "SPAW-WIT » CenTIG 200 «";

    return(<>
        <main>
        <div className="tlo" />
        <section className="intro">
            <div className="wrapperIntro" style={{width: '100%'}}>
                <div className="cowelderLewa">
                    <h1>Jednofazowe urządzenie spawalnicze CenTIG 200</h1>
                    <p>CenTIG to nowa seria jednofazowych urządzeń spawalniczych TIG, które zapewniają niezrównaną wydajność przy 200 amperach. Zaprojektowany dla zapracowanych profesjonalnych spawaczy TIG, CenTIG łączy moc i mobilność w wyjątkowo lekkiej obudowie. Zaspokaja szeroki zakres potrzeb i zastosowań przemysłowych. Niezależnie od tego, czy chodzi o naprawy i konserwację, czy większe zadania produkcyjne.</p>
                </div>
                <div className="cowelderPrawa centigmain" />
            </div>
        </section>
        <section className="cowelderSzczegoly">
            <div className="wrapperSrodek col" style={{gap: '40px'}}>
                <div className="cowelderWpis">
                    <div className="cowelderObraz centig1" />
                    <div className="cowelderTekst">
                        <h2>Najbardziej wydajna jednofazowa spawarka TIG</h2>
                        <p>CenTIG jest wystarczająco wytrzymały i niezawodny, aby pracować nieprzerwanie przy maksymalnym obciążeniu wynoszącym 100% cyklu pracy o natężeniu 200 A bez konieczności przestojów w celu zapobiegania przegrzaniu lub zużyciu.</p>
                    </div>
                </div>
                <div className="cowelderWpis">
                    <div className="cowelderObraz centig2" />
                    <div className="cowelderTekst">
                        <h2>Łatwość przenoszenia podczas spawania TIG</h2>
                        <p>CenTIG oferuje wyjątkową mobilność, idealną do pracy stacjonarnej. Jego lekka i kompaktowa konstrukcja zapewnia łatwy transport. Ważący jedynie 13,6 kg model AC/DC jest najlżejszy na rynku, a model DC o wadze 13 kg plasuje się w czołówce swojej klasy.</p>
                    </div>
                </div>
                <div className="cowelderWpis">
                    <div className="cowelderObraz centig3" />
                    <div className="cowelderTekst">
                        <h2>Wszechstronność dla każdego zastosowania</h2>
                        <p>CenTIG sprawdza się w różnych zastosowaniach, od napraw po codzienną produkcję. Dostępne konfiguracje obejmują modele DC i AC/DC z funkcją pulsu, chłodzone powietrzem lub wodą. Wyposażone we wszystkie niezbędne funkcje, spawarki gotowe są na każde wyzwanie.</p>
                    </div>
                </div>
            </div>
        </section>
        </main>
    </>);
};