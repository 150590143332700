export default function Cowelder(props){
    document.title = "SPAW-WIT » CoWelder «";

    return(<>
        <meta property="og:title" content="SPAW-WIT » CoWelder «" />
        <meta property="twitter:title" content="SPAW-WIT » CoWelder «" />
        <main>
        <div className="tlo" />
        <section className="intro">
            <div className="wrapperIntro" style={{width: '100%'}}>
                <div className="cowelderLewa">
                    <h1>Pomocnicze ramię CoWelder</h1>
                    <p>Kompletne rozwiązanie spawalnicze, dostarczane jako w pełni zmontowane, gotowe do zainstalowania i przekazania do użytkowania wraz z ramieniem robota, prąd zasilania spawarki 300/400 A , uchwyt spawalniczy i stół roboczy.
                    Najmniejszy i najbardziej inteligentny zautomatyzowany system spawania na rynku. Idealny do spawania elementów prostych, bez względu na ilość i częstotliwość. Nowy asystent w profesjonalnych warsztatach spawania.</p>
                </div>
                <div className="cowelderPrawa">
                    <iframe
                        src="https://player.vimeo.com/video/254314473?portrait=0"
                        frameBorder="0"
                        allowFullScreen={true}
                        id="iFrameResizer0"
                        scrolling="no"
                        texttrack="pl"
                        style={{overflow: 'hidden'}}
                        width="100%"
                        title="Prezentacja CoWelder"
                    />
                </div>
            </div>
        </section>
        <section className="cowelderSzczegoly">
            <div className="wrapperSrodek col" style={{gap: '40px'}}>
                <div className="cowelderWpis">
                    <div className="cowelderObraz cowelder4" />
                    <div className="cowelderTekst">
                        <h2>OSZCZĘDNOŚCI KOSZTÓW PRODUKCJI</h2>
                        <p>Prosta konfiguracja, w połączeniu z łatwym programowaniem przekłada się na znaczące oszczędności kosztów produkcji. Inwestowanie w doświadczonych programistów robotów nie jest konieczne. CoWelder™ oznacza krótki okres zwrotu z inwestycji.</p>
                    </div>
                </div>
                <div className="cowelderWpis">
                    <div className="cowelderObraz cowelder2" />
                    <div className="cowelderTekst">
                        <h2>OPTYMALIZACJA ZADAŃ SPAWANIA</h2>
                        <p>Wydajesz dużo na niewielkie partie ręcznie wykonywanych prac spawalniczych? Potrzebujesz optymalizacji efektywności, zapewnienia jednolitości produkcji seryjnej i zawsze wysokiej jakości spawania. Bardzo łatwo zapewni to nasz program, który jest opłacalny również do spawania małych partii. To wielka wygrana dla szefa i spawacza.</p>
                    </div>
                </div>
                <div className="cowelderWpis">
                    <div className="cowelderObraz cowelder3" />
                    <div className="cowelderTekst">
                        <h2>ŁATWE PROGRAMOWANIE</h2>
                        <p>Programowanie jest proste i intuicyjne, nawet dla niedoświadczonych operatorów. Pozwala na tworzenie katalogu detali z dołączonym programem i na przełączanie między nimi niezliczoną ilość razy w ciągu dnia pracy. W zależności od złożoności nowego przedmiotu obrabianego programowanie zajmuje tylko około pół godziny.</p>
                    </div>
                </div>
            </div>
        </section>
        </main>
    </>);
};