export default function Gasiq(props) {

	document.title = "SPAW-WIT » GasiQ «";

	const produkty = [
		{
			obraz: "/Obrazy/Redduktor-duzej-przepustowosci.jpg",
			opis: "Reduktor o dużej przepustowości 500l do wiązki butli zasilających sieć",
		},
		{
			obraz: "/Obrazy/220_prod_large.jpg",
			opis: "Reduktor butlowy o przepływie 20l lub 34l na minutę",
		},
		{
			obraz: "/Obrazy/Optimator-sieciowy.jpg",
			opis: "Reduktor sieciowy o przepływie 20l lub 34l na minutę",
		},
		{
			obraz: "/Obrazy/r1.jpg",
			opis: "Reduktor butlowy o przepływie 34l z wyjściem na poduszkę gazową",
		},
		{
			obraz: "/Obrazy/optymator-butlowy-dwa-rotametry-z-blokada.jpg",
			opis: "Reduktor sieciowy o przepływie 34l na minutę z wyjściem na poduszkę gazową",
		},
		{
			obraz: "/Obrazy/punkt-z-wyjsciem-na-poduszke.jpg",
			opis: "Kompletny punkt poboru z wyjściem na poduszkę gazową",
		},
		{
			obraz: "/Obrazy/Flowmeter.jpg",
			opis: "Rotametr do poduszki gazowej",
		},
		{
			obraz: "/Obrazy/zawor--z-przylaczem.jpg",
			opis: "Zawór do punktu poboru z króćcem do wlutowania oraz mocowaniem do szyny montażowej",
		},
		{
			obraz: "/Obrazy/przylacze-na-dwa-optymatory-sieciowe.jpg",
			opis: "Trójnik pod dwa reduktory sieciowe",
		},
		{
			obraz: "/Obrazy/listwa-mocujaca.jpg",
			opis: "Szyna montażowa do punktu poboru",
            margin: true
		},
	];

	return (
		<>
			<main>
				<div className="tlo" />
				<section className="dystrybucja">
					<div className="wrapperSrodek col">
                        <h1>Przykładowe dostępne produkty firmy GasiQ</h1>
                        <hr />
                        <h3>Jeśli poszukujesz konkretnego produktu, który nie jest tutaj wyświetlony, skontaktuj się z nami, a napewno dobierzemy przedmiot spełniający twoje potrzeby.</h3>
                        <div className="produktyGasiq">
                            {produkty.map((i, k) => {
                                return(
                                    <div className="produktGasiq" key={`gasiq-${k}`}>
                                        <div className="gasiqObraz" style={{backgroundImage: `url(${i.obraz})`, marginBottom: `${i.margin ? "-30px" : ""}`}} />
                                        <div className="gasiqOpis">
                                            {i.opis}
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
				</section>
			</main>
		</>
	);
}
