import { NavLink } from "react-router-dom";
import { useState, useLayoutEffect } from "react";
import { useLocation } from "react-router-dom";
export default function Header(props){
    const [ wysuniete, setWysuniete ] = useState(false);
    const path = useLocation();
    useLayoutEffect(() => {
        setWysuniete(false);
        window.scrollTo(0, 0);
    }, [path]);

    return(
        <header>
            <div className="wrapperHeader">
                <div className="logo" onClick={() => window.open("https://spaw-wit.pl", "_self").focus()}/>
                <nav className={`${wysuniete ? "wysuniete" : ""}`} >
                    <NavLink to="/">Główna</NavLink>
                    <NavLink to="/centig">CenTIG</NavLink>
                    <NavLink to="/cowelder">CoWelder</NavLink>
                    <NavLink to="/gasiq">GasiQ</NavLink>
                    <NavLink to="/pomiar">Pomiar</NavLink>
                    <NavLink to="/kontakt" className="navKontakt">Kontakt</NavLink>
                </nav>
                <div className={`menuMobilne ${wysuniete ? "menuMobilneOtwarte" : ""}`} onClick={() => setWysuniete(!wysuniete)}>
                    <span></span>
                    <span></span>
                    <span></span>
                </div>
            </div>
        </header>
    )
}