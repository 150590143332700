import { Link } from "react-router-dom"
export default function Footer(props){
    return(
        <footer>
            <div className="wrapperHeader">
                <div>
                    <h3>Adres</h3>
                    <p>01-494 Warszawa</p>
                    <p>ul. Widawska 15/9</p>
                    <p>woj. mazowieckie</p>
                </div>
                <div>
                    <h3>Copyright © 2024 SPAW-WIT</h3>
                    <Link to="/polityka">Polityka prywatności</Link>
                </div>
                <div>
                    <h3>Kontakt</h3>
                    <p>Telefon: <Link to="tel:+48604272996">604 272 996</Link></p>
                    <p>Mail: <Link to="mailto:w.pakiela@wp.pl">w.pakiela@wp.pl</Link></p>
                </div>
            </div>
        </footer>
    )
};