import { FaGears, FaHandshakeSimple, FaTruckRampBox, FaUserGraduate } from "react-icons/fa6";
import { IoStatsChart } from "react-icons/io5";

export default function Glowna(){
    document.title = "SPAW-WIT » Usługi spawalnicze «";

    const przekieruj = (nowaKarta, url) => {
        if(!url) return;
        window.open(url, nowaKarta ? "_blank" : "_self").focus();
    }

    return(
        <>
        <main>
        <div className="tlo" />
        <section className="intro glownaIntro">
            <div className="wrapperIntro">
                <div className="introLewa">
                    <h1>SPAWANIE TO NASZA PASJA</h1>
                    <p>Spektrum działalności SPAW-WIT związane jest ze spawalnictwem. Oferujemy usługi głównie w Warszawie, lecz również dojeżdżamy do innych miejscowości. Jeśli szukasz zaufanej firmy posiadającej bardzo dobrze wyszkolonych pracowników z profesjonalnym podejściem to doskonale trafiłeś!
                    Naszym piorytetem jest wysoka jakość, szybki czas realizacji oraz zadowolenie klienta!</p>
                    <div className="dwaPrzyciski">
                        <button className="primaryBtn" onClick={() => przekieruj(false, "#uslugi")}>Nasze Usługi</button>
                        <button className="primaryBtn" onClick={() => przekieruj(false, "#dystrybucja")}>Dystrybucja sprzętu</button>
                        <button className="secondaryBtn" onClick={() => przekieruj(false, "/kontakt")}>Kontakt</button>
                    </div>
                </div>
                <div className="introPrawa" />
            </div>
        </section>
        <section className="zakresUslug" id="uslugi">
            <div className="wrapperSrodek col">
                <h1>Zakres naszych usług</h1>
                <hr style={{background: "#444"}} />
                <h3>Oferujemy szeroki wachlarz profesjonalnych usług spawalniczych, które sprostają nawet najbardziej wymagającym oczekiwaniom.</h3>
                <div className="row uslugi">
                    <div className="usluga">
                        <div className="uslugaObrazek"><FaTruckRampBox /></div>
                        <h3>Dystrybucja sprzętu spawalniczego</h3>
                        <span>Oferujemy szeroki asortyment nowoczesnego sprzętu spawalniczego, od podstawowych narzędzi po zaawansowane technologie. Gwarantujemy wysoką jakość produktów oraz konkurencyjne ceny.</span>
                    </div>
                    <div className="usluga">
                        <div className="uslugaObrazek"><FaGears /></div>
                        <h3>Usługi spawalnicze</h3>
                        <span>Świadczymy kompleksowe usługi spawalnicze, obejmujące różne techniki spawania oraz różne materiały. Nasz doświadczony zespół zapewnia precyzyjne i trwałe spoiny, dostosowane do indywidualnych potrzeb klientów.</span>
                    </div>
                    <div className="usluga">
                        <div className="uslugaObrazek"><FaUserGraduate /></div>
                        <h3>Szkolenia spawaczy w zakładach pracy</h3>
                        <span>Prowadzimy profesjonalne szkolenia spawaczy bezpośrednio w zakładach pracy. Nasze kursy są dostosowane do specyficznych wymagań przemysłu, zapewniając najwyższy standard nauczania i praktyczne umiejętności.</span>
                    </div>
                    <div className="usluga">
                        <div className="uslugaObrazek"><FaHandshakeSimple /></div>
                        <h3>Doradztwo technologiczne</h3>
                        <span>Oferujemy fachowe doradztwo technologiczne w zakresie spawalnictwa. Pomagamy w doborze odpowiednich metod i materiałów spawalniczych, optymalizacji procesów oraz rozwiązywaniu problemów technicznych.</span>
                    </div>
                    <div className="usluga">
                        <div className="uslugaObrazek"><IoStatsChart /></div>
                        <h3>Pomiary zużycia gazów osłonowych</h3>
                        <span>Specjalizujemy się w precyzyjnych pomiarach zużycia gazów osłonowych. Nasze usługi pozwalają na optymalizację procesów spawalniczych oraz redukcję kosztów operacyjnych.</span>
                    </div>
                </div>
            </div>
        </section>
        <section className="dystrybucja" id="dystrybucja">
            <div className="wrapperSrodek col">
                <h1>Dystrybucja sprzętu spawalniczego</h1>
                <hr className="secondaryHr"/>
                <h3>Jesteśmy dystrybutorem szerokiego asortymentu sprzętu spawalniczego produkowanego przez szanowane na rynku firmy.</h3>
                <div className="row">
                    <div className="dystrybucjaWpis" onClick={() => przekieruj(true, "https://www.migatronic.com/pl") }>
                        <div className="dystrybucjaObraz migatronicImg" />
                        <div className="dystrybucjaTekst">
                            <h4>Migatronic</h4>
                            <p>Firma Migatronic rozwija inteligentną technologię procesu spawania ręcznego i zautomatyzowanego. Skupienie na optymalizacji procesów, by zakres prac spawalniczych stał się bardziej efektywny to jeden z piorytetów. Wierzą, że celem technologii jest służba człowiekowi, nie na odwrót.</p>
                        </div>
                    </div>
                    <div className="dystrybucjaWpis" onClick={() => przekieruj(true, "https://www.gasiq.com/en-gb/")}>
                        <div className="dystrybucjaObraz gasiqImg" />
                        <div className="dystrybucjaTekst">
                            <h4>GasiQ</h4>
                            <p>Rozwój GasiQ odbywa się przy ciągłym dążeniu do spełnienia wymagań sektorów przemysłowych. Piorytety to bezpieczeństwo oraz ergonomia. Produkty projektują za pomocą trójwymiarowych programów do modulacji bryłowej, co umożliwia etap szkicu.</p>
                        </div>
                    </div>
                    <div className="dystrybucjaWpis" onClick={() => przekieruj(true, "https://spartus.pl")}>
                        <div className="dystrybucjaObraz spartusImg" />
                        <div className="dystrybucjaTekst">
                            <h4>Spartus</h4>
                            <p>W obecnej rzeczywistości, gdy każdy odbiorca szuka wymiernych oszczędności, SPARTUS jest marką gwarantującą zaspokojenie oczekiwań spawaczy i osiągnięcie wymaganej jakości spoin za rozsądną cenę.</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </main>
        </>
    )
}