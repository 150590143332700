export default function Polityka(props){

    document.title = "SPAW-WIT » Polityka prywatności «";

    return(<>
        <main>
        <div className="tlo" />
        <section className="intro">
            <div className="wrapperIntro col">
            <h1>Polityka prywatności</h1>
            <ol>
<li><h4>POSTANOWIENIA OGÓLNE</h4>
<ol>
<li>
Administratorem danych osobowych zbieranych za pośrednictwem strony internetowej <strong>spaw-wit.pl</strong> jest
<strong>Witold Pakieła</strong> wykonujący/a działalność gospodarczą pod firmą <strong>SPAW-WIT</strong>, adres siedziby: Warszawa ul. Widawska 15/9, adres do doręczeń: , NIP: 5221289668, REGON: 142555191, wpisaną do Centralnej Ewidencji i Informacji o Działalności Gospodarczej, adres poczty elektronicznej: w.pakiela@wp.pl, dalej „Administrator”, będący/a jednoczeście Usługodawcą.
, miejsce wykonywania działalności: Warszawa ul. Widawska 15/9, adres do doręczeń: Warszawa ul. Widawska 15/9, NIP: 5221289668, REGON: 142555191, adres poczty elektronicznej (e-mail): w.pakiela@wp.pl, zwany/a dalej "Administratorem".
</li>
<li>
Dane osobowe zbierane przez Administratora za pośrednictwem strony internetowej są przetwarzane zgodnie z&nbsp;Rozporządzeniem Parlamentu Europejskiego i&nbsp;Rady (UE) 2016/679 z&nbsp;dnia 27 kwietnia 2016 r. w&nbsp;sprawie ochrony osób fizycznych w&nbsp;związku z&nbsp;przetwarzaniem danych osobowych i&nbsp;w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (ogólne rozporządzenie o&nbsp;ochronie danych), zwane dalej RODO oraz ustawą o ochronie danych osobowych z dnia 10 maja 2018 r.
</li>
</ol>
</li>
<li>
<h4>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH, CEL I ZAKRES ZBIERANIA DANYCH</h4>
<ol>
<li><strong>CEL PRZETWARZANIA I PODSTAWA PRAWNA.</strong> Administrator przetwarza dane osobowe za pośrednictwem strony <strong>spaw-wit.pl</strong> w przypadku: <i>brak</i>
<ol style={{listStyle: "lower-alpha"}}>
</ol>
</li>
<li><strong>RODZAJ PRZETWARZANYCH DANYCH OSOBOWYCH. </strong>Administrator przetwarza następujące kategorie danych osobowych użytkownika: <i>brak</i>
<ol style={{listStyle: "lower-alpha"}}>
</ol>
</li>
<li><strong>OKRES ARCHIWIZACJI DANYCH OSOBOWYCH.</strong> Dane osobowe użytkowników przechowywane są przez Administratora:
<ol style={{listStyle: "lower-alpha"}}>
<li>w przypadku, gdy podstawą przetwarzania danych jest wykonanie umowy, tak długo, jak jest to niezbędne do wykonania umowy, a&nbsp;po tym czasie przez okres odpowiadający okresowi przedawnienia roszczeń. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a&nbsp;dla roszczeń o&nbsp;świadczenia okresowe oraz roszczeń związanych z&nbsp;prowadzeniem działalności gospodarczej - trzy lata.</li>
<li>w przypadku, gdy podstawą przetwarzania danych jest zgoda, tak długo, aż zgoda nie zostanie odwołana, a&nbsp;po odwołaniu zgody przez okres czasu odpowiadający okresowi przedawnienia roszczeń jakie może podnosić Administrator i&nbsp;jakie mogą być podnoszone wobec niego. Jeżeli przepis szczególny nie stanowi inaczej, termin przedawnienia wynosi lat sześć, a&nbsp;dla roszczeń o&nbsp;świadczenia okresowe oraz roszczeń związanych z&nbsp;prowadzeniem działalności gospodarczej - trzy lata.</li>
</ol>
</li>
<li>Podczas korzystania ze strony internetowej mogą być pobierane dodatkowe informacje, w&nbsp;szczególności: adres IP przypisany do komputera użytkownika lub zewnętrzny adres IP dostawcy Internetu, nazwa domeny, rodzaj przeglądarki, czas dostępu, typ systemu operacyjnego.</li>
<li>Od użytkowników mogą być także gromadzone dane nawigacyjne, w&nbsp;tym informacje o&nbsp;linkach i&nbsp;odnośnikach, w&nbsp;które zdecydują się kliknąć lub innych czynnościach, podejmowanych na stronie internetowej. Podstawą prawną tego rodzaju czynności jest prawnie uzasadniony interes Administratora (art. 6 ust. 1 lit. f RODO), polegający na ułatwieniu korzystania z&nbsp;usług świadczonych drogą elektroniczną oraz na poprawie funkcjonalności tych usług.</li>
<li>Podanie danych osobowych przez użytkownika jest dobrowolne.</li>
<li>Dane osobowe będą przetwarzane także w sposób zautomatyzowany w formie profilowania, o ile użytkownik wyrazi na to zgodę na podstawie art. 6 ust. 1 lit. a) RODO. Konsekwencją profilowania będzie przypisanie danej osobie profilu w celu podejmowania dotyczących jej decyzji bądź analizy lub przewidywania jej preferencji, zachowań i postaw.</li>
<li>Administrator dokłada szczególnej staranności w celu ochrony interesów osób, których dane dotyczą, a w szczególności zapewnia, że zbierane przez niego dane są:
<ol style={{listStyle: "lower-alpha"}}>
<li>przetwarzane zgodnie z prawem, </li>
<li>zbierane dla oznaczonych, zgodnych z prawem celów i niepoddawane dalszemu przetwarzaniu niezgodnemu z tymi celami,</li>
<li>merytorycznie poprawne i adekwatne w stosunku do celów, w jakich są przetwarzane oraz przechowywane w postaci umożliwiającej identyfikację osób, których dotyczą, nie dłużej niż jest to niezbędne do osiągnięcia celu przetwarzania.</li>
</ol>
</li>
</ol>
</li>
<li>
<h4>UDOSTĘPNIENIE DANYCH OSOBOWYCH</h4>
<ol>
<li>Dane osobowe użytkowników przekazywane są dostawcom usług, z&nbsp;których korzysta Administrator przy prowadzeniu strony internetowej. Dostawcy usług, którym przekazywane są dane osobowe, w&nbsp;zależności od uzgodnień umownych i&nbsp;okoliczności, albo podlegają poleceniom Administratora co do celów i&nbsp;sposobów przetwarzania tych danych (podmioty przetwarzające) albo samodzielnie określają cele i&nbsp;sposoby ich przetwarzania (administratorzy).</li>
<li>Dane osobowe użytkowników są przechowywane wyłącznie na terenie Europejskiego Obszaru Gospodarczego (EOG).</li>
</ol>
</li>
<li>
<h4>PRAWO KONTROLI, DOSTĘPU DO TREŚCI WŁASNYCH DANYCH ORAZ ICH POPRAWIANIA</h4>
<ol>
<li>Osoba, której dane dotyczą, ma prawo dostępu do treści swoich danych osobowych oraz prawo ich sprostowania, usunięcia, ograniczenia przetwarzania, prawo do przenoszenia danych, prawo wniesienia sprzeciwu, prawo do cofnięcia zgody w dowolnym momencie bez wpływu na zgodność z prawem przetwarzania, którego dokonano na podstawie zgody przed jej cofnięciem.</li>
<li>Podstawy prawne żądania użytkownika:
<ol style={{listStyle: "lower-alpha"}}>
<li><strong>Dostęp do danych</strong> – art. 15 RODO</li>
<li><strong>Sprostowanie danych</strong> – art. 16 RODO.</li>
<li><strong>Usunięcie danych (tzw. prawo do bycia zapomnianym)</strong> – art. 17 RODO.</li>
<li><strong>Ograniczenie przetwarzania</strong> – art. 18 RODO.</li>
<li><strong>Przeniesienie danych</strong> – art. 20 RODO.</li>
<li><strong>Sprzeciw</strong> – art. 21 RODO</li>
<li><strong>Cofnięcie zgody</strong> – art. 7 ust. 3 RODO.</li>
</ol>
</li><li>W celu realizacji uprawnień, o których mowa w pkt 2 można wysłać stosowną wiadomość e-mail na adres: w.pakiela@wp.pl.</li>
<li>W sytuacji wystąpienia przez użytkownika z&nbsp;uprawnieniem wynikającym z&nbsp;powyższych praw, Administrator spełnia żądanie albo odmawia jego spełnienia niezwłocznie, nie później jednak niż w&nbsp;ciągu miesiąca po jego otrzymaniu. Jeżeli jednak - z&nbsp;uwagi na skomplikowany charakter żądania lub liczbę żądań – Administrator nie będzie mógł spełnić żądania w&nbsp;ciągu miesiąca, spełni je w&nbsp;ciągu kolejnych dwóch miesięcy informując użytkownika uprzednio w&nbsp;terminie miesiąca od otrzymania żądania - o&nbsp;zamierzonym przedłużeniu terminu oraz jego przyczynach.</li>
<li>W przypadku stwierdzenia, że przetwarzanie danych osobowych narusza przepisy RODO, osoba, której dane dotyczą, ma prawo wnieść skargę do Prezesa Urzędu Ochrony Danych Osobowych.</li>
</ol>
</li>
<li>
<h4>PLIKI "COOKIES"</h4>
<div id="cookie"></div>
<ol>
<li>Strona Administratora&nbsp;używa plików&nbsp;„cookies”.</li>
<li>Instalacja plików „cookies” jest konieczna do prawidłowego świadczenia usług na stronie internetowej. W plikach „cookies" znajdują się informacje niezbędne do prawidłowego funkcjonowania strony, a także dają one także możliwość opracowywania ogólnych statystyk odwiedzin strony internetowej.</li>
<li>W ramach strony stosowane są rodzaje plików "cookies":
<ol style={{listStyle: "lower-alpha"}}>
</ol>
</li>
<li>Administrator wykorzystuje własne pliki cookies w celu lepszego poznania sposobu interakcji użytkownika w zakresie zawartości strony. Pliki gromadzą informacje o sposobie korzystania ze strony internetowej przez użytkownika, typie strony, z jakiej użytkownik został przekierowany oraz liczbie odwiedzin i czasie wizyty użytkownika na stronie internetowej. Informacje te nie rejestrują konkretnych danych osobowych użytkownika, lecz służą do opracowania statystyk korzystania ze strony.</li>
<li>Użytkownik ma prawo zadecydowania w zakresie dostępu plików „cookies” do swojego komputera poprzez ich uprzedni wybór w oknie swojej przeglądarki. &nbsp;Szczegółowe informacje o możliwości i sposobach obsługi plików „cookies” dostępne są w ustawieniach oprogramowania (przeglądarki internetowej).</li>
</ol>
</li>
<li>
<h4>POSTANOWIENIA KOŃCOWE</h4>
<ol>
<li>Administrator stosuje środki techniczne i organizacyjne zapewniające ochronę przetwarzanych danych osobowych odpowiednią do zagrożeń oraz kategorii danych objętych ochroną, a w szczególności zabezpiecza dane przed ich udostępnieniem osobom nieupoważnionym, zabraniem przez osobę nieuprawnioną, przetwarzaniem z naruszeniem obowiązujących przepisów oraz zmianą, utratą, uszkodzeniem lub zniszczeniem.</li>
<li>Administrator udostępnia odpowiednie środki techniczne zapobiegające pozyskiwaniu i modyfikowaniu przez osoby nieuprawnione, danych osobowych przesyłanych drogą elektroniczną.</li>
<li>W sprawach nieuregulowanych niniejszą Polityką prywatności stosuje się odpowiednio przepisy RODO oraz inne właściwe przepisy prawa polskiego.</li>
</ol>
</li>
</ol>
            </div>
        </section>
        </main>
    </>);
};