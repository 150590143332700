import { useEffect } from "react";

export default function Pusta(props){
    document.title = "SPAW-WIT » Usługi spawalnicze «";

    useEffect(() => {
        const przek = setTimeout(() => {
            window.location.href = "/";
        }, 3000);
        return () => clearTimeout(przek);
    }, []);

    return(
        <>
        Zabłądziłeś :)
        </>
    )
}