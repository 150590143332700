import { FaPhoneSquareAlt } from "react-icons/fa";
import { FaBuilding, FaEnvelope, FaLocationArrow, FaMapLocation } from "react-icons/fa6";

export default function Kontakt(props){
    document.title = "SPAW-WIT » Kontakt «";

    return(<>
        <main>
        <div className="tlo" />
        <section className="intro">
            <div className="wrapperIntro wrapperKontakt">
                <div className="kontaktDane">
                    <h1>Kontakt</h1>
                    <hr />
                    <p>SPAW-WIT © Witold Pakieła</p>
                    <p><FaMapLocation /> Warszawa 01-494</p>
                    <p><FaBuilding /> ul. Widawska 15/9</p>
                    <p><FaLocationArrow /> woj. Mazowieckie</p>
                    <br />
                    <p><FaPhoneSquareAlt /> Telefon: 604 272 996</p>
                    <p><FaEnvelope /> Mail: w.pakiela@wp.pl</p>
                </div>
                <iframe title="Lokalizacja" className="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1727.1047446335651!2d20.923145736418313!3d52.25255655441189!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x471ecb0061471707%3A0xbcaa2167215153b4!2sWidawska+15%2C+01-494+Warszawa!5e0!3m2!1spl!2spl!4v1561922757601!5m2!1spl!2spl" width="100%" height="100%" frameBorder="0" style={{border: 'none'}} allowFullScreen="" />
            </div>
        </section>
        </main>
    </>);
};