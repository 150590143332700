export default function Pomiar(props){

    document.title = "SPAW-WIT » Pomiar «";

    return(<>
        <main>
        <div className="tlo" />
        <section className="intro">
            <div className="wrapperIntro">
                <div className="introLewaGasiq">
                    <h1>Profesjonalne Pomiary Zużycia Gazu Osłonowego</h1>
                    <p>Przeprowadzamy dokładne i profesjonalne pomiary zużycia gazu osłonowego, który jest wykorzystywany podczas pracy na stanowisku spawalniczym z rejestracją chwilowego poboru gazu i całkowitego zużycia na dany element.
                    Dzięki takim pomiarom możemy dokładnie ocenić, jakie korzyści przynosi zastosowanie nowoczesnych technologii, takich jak Optymator firmy Gasiq, w porównaniu do tradycyjnych rozwiązań. To pozwala na podejmowanie świadomych decyzji dotyczących inwestycji w sprzęt oraz optymalizacji procesów produkcyjnych.
                    Aby lepiej zrozumieć różnice w zużyciu gazu przy różnych konfiguracjach, prezentujemy przykładowy pomiar.</p>
                    <ul>
                        <li><b>Czerwony Wykres:</b> Przedstawia zużycie gazu podczas pracy na tradycyjnym reduktorze, który był wcześniej zainstalowany na stanowisku spawalniczym. Ten wykres ilustruje standardowy sposób zużycia gazu bez dodatkowej optymalizacji.</li>
                        <li><b>Niebieski Wykres:</b> Przedstawia zużycie gazu podczas pracy z użyciem Optymatora firmy Gasiq. Optymator to zaawansowane urządzenie, które optymalizuje zużycie gazu osłonowego, minimalizując straty i zwiększając efektywność.</li>
                    </ul>
                    
                </div>
                <div className="introPrawaGasiq" style={{backgroundImage: `url(/Obrazy/pomiar2.jpg)`}} />
            </div>
        </section>
        </main>
    </>);
};