import {
	Route,
	BrowserRouter as Router,
	Routes,
} from "react-router-dom";
import Glowna from "./Strony/Glowna";
import Kontakt from "./Strony/Kontakt";
import Pomiar from "./Strony/Pomiar";
import Cowelder from "./Strony/Cowelder";
import Centig from "./Strony/Centig";
import Gasiq from "./Strony/Gasiq";
import Polityka from "./Strony/Polityka";
import Pusta from "./Strony/Pusta";
import Footer from "./Komponenty/Footer";
import Header from "./Komponenty/Header";

export default function Katalogi() {

    return (
        <>
        <Router>
            <Header />
            <Routes>
                <Route path="/" element={<Glowna />} exact/>
                <Route path="/kontakt" element={<Kontakt />} />
                <Route path="/pomiar" element={<Pomiar />} />
                <Route path="/cowelder" element={<Cowelder />} />
                <Route path="/centig" element={<Centig />} />
                <Route path="/gasiq" element={<Gasiq />} />
                <Route path="/polityka" element={<Polityka />} />
                <Route path="*" element={<Pusta />} />
            </Routes>
            <Footer />
        </Router>
        </>
    );
};